<template>
  <loading v-if="pageLoading" />

  <div v-else>
    <div
      v-if="
        allowCreate && adminContext && table.results.length && siteId.length
      "
      class="message is-dark"
    >
      <div class="message-body has-padding-75">
        <apart>
          <p>Create a task on behalf of this user.</p>
          <button
            class="button is-dark is-rounded is-outlined"
            @click="$emit('createTask')"
          >
            Create task
          </button>
        </apart>
      </div>
    </div>

    <data-filter
      v-if="allowFiltering && showDataFilter"
      :filters="filters"
      :active-filters="activeFilters"
      @filtersChanged="filtersChanged"
    />

    <b-table
      :data="table.data"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      backend-sorting
      hoverable
      striped
      class="has-vertical-align-middle"
      @sort="onSort"
    >
      <template slot="empty">
        <no-results
          icon="briefcase"
          title="No tasks"
          message="There are no tasks to show."
        />
      </template>

      <template slot-scope="props">
        <b-table-column label="Status" field="status" width="100">
          <status-label
            :status="$store.getters['tasks/task/status'](props.row.status)"
          />
        </b-table-column>

        <b-table-column label="Reference" field="reference" width="150">
          <router-link
            :to="{ path: getTaskPath(props.row) }"
            class="has-text-dark"
          >
            <u>{{ props.row.reference }}</u>
          </router-link>
          <p class="is-size-7 has-text-grey">
            {{ props.row.participants.length }} participant{{
              props.row.participants.length > 1 ? "s" : ""
            }}
          </p>
        </b-table-column>

        <b-table-column label="Task" field="taskType">
          <router-link
            :to="{ path: getTaskPath(props.row) }"
            class="has-text-dark"
          >
            <u>{{ props.row.taskType }}</u>
          </router-link>
          <p
            style="max-width: 150px;"
            class="has-text-grey has-overflow-ellipsis-right"
          >
            <em>{{ props.row.subject || "--" }}</em>
          </p>
        </b-table-column>

        <b-table-column v-if="!siteId.length" label="Site" field="fqdn">
          <router-link
            v-if="props.row.fqdn"
            :to="{ path: `/sites/${props.row.siteId}` }"
            class="has-text-dark"
          >
            <u
              style="display: inline-block; max-width: 200px;"
              class="has-overflow-ellipsis-left"
              >{{ props.row.fqdn }}</u
            >
          </router-link>

          <span v-else class="has-text-grey">–</span>
        </b-table-column>

        <b-table-column v-if="!participantId && !siteId" label="Created by">
          <router-link
            v-if="
              props.row.participantsData &&
                props.row.participantsData[props.row.authorId]
            "
            :to="{ path: `/admin/users/${props.row.authorId}` }"
            class="has-text-dark"
          >
            <u>{{ props.row.participantsData[props.row.authorId].email }}</u>
          </router-link>

          <span v-else class="has-text-grey">–</span>
        </b-table-column>

        <b-table-column
          label="Last updated"
          field="dateUpdated"
          sortable
          width="200"
          >{{ $moment(props.row.dateUpdated.toDate()).from() }}</b-table-column
        >
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import { collection, orderBy, query, where } from "@firebase/firestore";
export default {
  name: "TasksTable",

  mixins: [require("@mixins/table-with-filtering-and-sorting").default],

  props: {
    siteId: {
      type: String,
      required: false,
      default: ""
    },
    participantId: {
      type: String,
      required: false,
      default: ""
    },
    resellerId: {
      type: String,
      required: false,
      default: ""
    },
    allowFiltering: {
      type: Boolean,
      required: false,
      default: false
    },
    isOpen: {
      required: false,
      default: undefined,
      validator: value => {
        return ["boolean"].includes(typeof value);
      }
    },
    includeDeleted: {
      type: Boolean,
      required: false,
      default: false
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: true
    }
  },

  data() {
    let filters = [
      {
        key: "reference",
        label: "Reference",
        type: "string"
      },
      {
        key: "status",
        label: "Status",
        options: this.$store.state.tasks.statuses,
        type: "select-radio"
      },
      {
        key: "dateCreated",
        label: "Created",
        type: "date"
      }
    ];

    if (this.participantId.length) {
      this.$_.remove(filters, n => n.key === "active");
    }

    return {
      pageLoading: true,
      filters: filters,
      table: {
        sort: { field: "dateUpdated", direction: "desc" }
      }
    };
  },
  methods: {
    getFirestoreRef() {
      let ref = collection(this.$firestore, "tasks");
      if (this.includeDeleted === true) {
        ref = query(ref, where("status", ">", "0-00"));
      } else {
        ref = query(ref, where("status", ">", "0-01"));
      }
      if (this.$_.isBoolean(this.isOpen)) {
        ref = this.isOpen
          ? query(ref, where("status", ">", "1-"))
          : query(ref, where("status", "<", "1-"));
      }
      ref = query(ref, orderBy("status", "desc"));
      if (this.siteId.length) {
        ref = query(ref, where("siteId", "==", this.siteId));
      }
      if (this.participantId) {
        ref = query(
          ref,
          where("participants", "array-contains", this.participantId)
        );
      }
      if (this.resellerId) {
        ref = query(ref, where("resellerId", "==", this.resellerId));
      }
      return this.buildFirestoreRef(ref);
    },
    afterDataLoad() {
      this.pageLoading = false;
    },
    getTaskPath(task) {
      return this.adminContext
        ? `/tasks/magic/${task._id}`
        : `/tasks/${task._id}`;
    }
  }
};
</script>
